<template>
  <div>
    <el-row>
      <el-col :span="24">
        <div class="titleBox">
          <div>
            您好!
            <span v-if="$store.state.user.realName">{{
                $store.state.user.realName
            }}</span>
          </div>
          <div v-if="$store.state.user.companyName && (isAdmin || isCustomer || isTraffic_bureau)">
            {{ $store.state.user.companyName }}
          </div>
        </div>
      </el-col>
    </el-row>
    <div class="border">
      <div>
        <el-row>
          <el-col :span="24" class="titleContent">
            <span class="title iconTitle">数据概览</span>
          </el-col>
        </el-row>
        <div class="toplist">
          <div class="toptag" v-for="(item, ind) in titlelist" :key="ind" @click="toUrl(item, item)">
            <div class="topimg">
              <img :src="item.img" alt="" style="width: 100%; height: 100%" />
            </div>
            <div class="topcontent">
              <p class="toptitle" style="display: flex">
                <span :style="'font-size:14px;color:' + item.color">{{
                    item.title
                }}</span>
                <span style="color: #999">{{ item.unit }} </span>
                <!-- <el-tooltip
                    popper-class="p-tooltip"
                    effect="dark"
                    :content="item.tooltip"
                    placement="bottom-start"
                  >
                    <el-button class="aaac" v-show="item.tooltip.length > 0"
                      >?</el-button
                    >
                  </el-tooltip> -->
              </p>
              <div style="margin-top: 5px; font-size: 20px" class="toptnum">
                {{ item.num }}
              </div>
            </div>
          </div>

        </div>
      </div>
      <div style="height: 20px; background: #fff"></div>
      <el-row :gutter="16">
        <el-col :span="24">
          <div>
            <el-row>
              <el-col>
                <!-- <div class="navTitle">流调趋势图</div> -->
                <div class="tabTitle">
                  <div class="text">7天流调趋势图</div>
                  <div style="margin-right: 20px">
                    <el-date-picker v-model="timeValue" type="daterange" range-separator="至" start-placeholder="开始日期"
                      end-placeholder="结束日期" :picker-options="pickerOptions" value-format="yyyy-MM-dd">
                    </el-date-picker>
                  </div>
                </div>
              </el-col>
            </el-row>
            <el-row id="canvasBox">
              <el-col :span="24">
                <div style="width: 100%; height: 390px">
                  <ve-line v-if="chartData.rows.length > 0" :data="chartData" :extend="barExtend" :settings="settings"
                    height="390px" width="calc(100vw - 50px)" :colors="colors"></ve-line>
                  <el-empty v-else :image-size="200"></el-empty>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
      <!-- </div>
    <div class="border" style="margin-top:24px"> -->
      <el-row :gutter="16" v-if="isAdmin || isCustomer">
        <div class="border" style="margin: 24px; height: 1px; border-width: 0 0 1px 0"></div>
        <el-col :span="24">
          <div>
            <el-row>
              <el-col>
                <div class="tabTitle">
                  <div class="text">7天资金流水</div>
                  <div style="margin-right: 20px">
                    <el-date-picker v-model="capitalValue" type="daterange" range-separator="至" start-placeholder="开始日期"
                      end-placeholder="结束日期" :picker-options="pickerOptions" value-format="yyyy-MM-dd">
                    </el-date-picker>
                  </div>
                </div>
              </el-col>
            </el-row>
            <el-row id="canvasBox1">
              <el-col>
                <div style="width: 100%; height: 390px">
                  <ve-line v-if="chartData1.rows.length > 0" :data="chartData1" :extend="barExtend" :settings="settings"
                    height="390px" width="calc(100vw - 50px)" :colors="colors"></ve-line>
                  <el-empty v-else :image-size="200"></el-empty>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script type="text/babel">
const sj = require("@/assets/img/sj.png");
const cl = require("@/assets/img/cl.png");
const gs = require("@/assets/img/gs.png");
const ld = require("@/assets/img/ld.png");
const ts = require("@/assets/img/ts.png");
import vueSeamlessScroll from "vue-seamless-scroll";
import api from "@/api/TripDataAnalysisApi";
import basePage from '../../base/basePage.vue'
// import NoticeApi from "@/api/NoticeApi";
import * as DateUtils from "@/utils/DateUtils";
export default {
  extends:basePage,
  data() {
    let that = this;
    this.chartSettings = {};
    return {
      listData: [],
      vhWidth: "100%",
      timeValue: [],
      capitalValue: [],
      colors: [
        "#4BB019",
        "#2c6aff",
        "#FCBB3F",
        "#4ECF86",
        "#FF8D03",
        "#999999",
        "#409EFF",
      ],
      pickerOptions: {
        onPick: ({ maxDate, minDate }) => {
          that.pickerMinDate = minDate.getTime();
          if (maxDate) {
            that.pickerMinDate = "";
          }
        },
        disabledDate: (time) => {
          if (that.pickerMinDate) {
            const day30 = (30 - 1) * 24 * 3600 * 1000;
            let maxTime = that.pickerMinDate + day30;
            if (maxTime > new Date()) {
              maxTime = new Date();
              return (
                time.getTime() > maxTime ||
                time.getTime() < that.pickerMinDate - day30
              );
            } else {
              return (
                maxTime < time.getTime() ||
                time.getTime() < that.pickerMinDate - day30
              );
            }
          }

          return time.getTime() > Date.now();
        },
      },
      Index: 0,
      chartData: {
        columns: ["dateTime", "num"],
        rows: [{ dateTime: "0", num: 0 }],
      },
      chartData1: {
        columns: ["dateTime", "num"],
        rows: [],
      },
      settings: {
        type: "line",
        area: true,
        itemStyle: {
          color: "#73A0FA",
        },
      },
      barExtend: {
        yAxis: {
          type: "value",
          // name: "万元",
          position: "left",
          splitLine: {
            show: true,
            lineStyle: {
              color: ["#c7c7c7"],
              type: "dashed",
            },
          },
        },
        series: {
          type: "line", //增加type字段
          label: {
            normal: {
              position: "top",
              show: true,
            },
          },
          smooth: false,
          symbol: "circle",
          symbolSize: 8,
          emphasis: {
            itemStyle: {
              opacity: 1,
            },
          },
          itemStyle: {
            // opacity: 0,
            borderWidth: 2,
            borderColor: "#fff",
          },
          areaStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "#4BB019", // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: "#8ECA70", // 100% 处的颜色
                },
              ],
              global: false, // 缺省为 false
            },
            opacity: 0.1,
          },
        },
        axisPointer: {
          lineStyle: {
            color: "#EBEEFD", //竖线
          },
        },
        legend: {
          show: true,
          selectedMode: true,
        },
        barWidth: 30,
        xAxis: {
          axisLabel: {
            show: true,
            interval: 0,
          },
        },
        grid: {
          bottom: 10,
          top: 50,
        },
        tooltip: {
          show: false,
        },
      },
      name: "张三",
      tableList: [],
      changeTimeList: [],
      titlelist: [],
    };
  },
  components: {
    vueSeamlessScroll,
  },
  computed: {
    defaultOption() {
      return {
        step: 0.2, // 数值越大速度滚动越快
        limitMoveNum: this.tableList.length, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  watch: {
    timeValue() {
      this.getFindOrderEveryDayAmount();
    },
    capitalValue() {
      this.findUserAccountAmountList();
    },
  },
  methods: {
    toVIPDetail(id) {
      this.$router.push({
        name: "orderDetail",
        params: {
          id,
        },
      });
    },
    toUrl(item) {
      if (!item.url) return;
      this.$router.push({
        path: item.url,
        query: {
          status: item.status,
        },
      });
    },

    //获取待办事项
    getFindWaitingWorkList() {
      api.workBenchCount().then((resp) => {
        if (resp.code == "200") {
          this.titlelist.map((item) => {
            item.num = resp.data[item.name] ? resp.data[item.name] : 0;
          });
        }
      });
    },
    // 查询每天订单金额
    getFindOrderEveryDayAmount() {
      let endTimeStr = "";
      let startTimeStr = "";
      if (this.timeValue && this.timeValue.length > 0) {
        endTimeStr = this.timeValue[1];
        startTimeStr = this.timeValue[0];
      }
      //流调统计折现表
      api
        .findCompanyEventLogCountList({
          endTimeStr,
          startTimeStr,
        })
        .then((resp) => {
          // console.log(resp);
          if (resp.code == "200") {
            let chartData = {
              columns: ["dateTime"],
              rows: [],
            };
            if (resp.data && resp.data.length > 0) {
              for (let i = 0; i < resp.data.length; i++) {
                const item = resp.data[i];
                chartData.columns.push(item.companyName);

                for (let j = 0; j < item.list.length; j++) {
                  const sub = item.list[j];
                  let obj;
                  if (i == 0) {
                    obj = { dateTime: sub.dateTimeStr.substring(5) };
                    chartData.rows.push(obj);
                  } else {
                    obj = chartData.rows[j];
                  }
                  obj[item.companyName] = sub.count;
                }
              }
            }
            this.chartData = chartData;
          }
        });
    },
    findUserAccountAmountList() {
      //资金统计
      api
        .findUserAccountAmountList({
          startTimeStr: this.capitalValue[0],
          endTimeStr: this.capitalValue[1],
        })
        .then((resp) => {
          if (resp.code == "200") {
            let chartData = {
              columns: ["dateTime"],
              rows: [],
            };
            if (resp.data && resp.data.length > 0) {
              for (let i = 0; i < resp.data.length; i++) {
                const item = resp.data[i];
                chartData.columns.push(item.companyName);

                for (let j = 0; j < item.list.length; j++) {
                  const sub = item.list[j];
                  let obj;
                  if (i == 0) {
                    obj = { dateTime: sub.dateTimeStr.substring(5) };
                    chartData.rows.push(obj);
                  } else {
                    obj = chartData.rows[j];
                  }
                  obj[item.companyName] = sub.amount;
                }
              }
            }
            this.chartData1 = chartData;
          }
        });
    },
    getTime(param) {
      return DateUtils.getDateTime(param);
    },
    toDetail(id) {
      this.$router.push({
        name: "notificationDetail",
        params: {
          id,
        },
      });
    },
    formatDate(date) {
      var myyear = date.getFullYear();
      var mymonth = date.getMonth() + 1;
      var myweekday = date.getDate();

      if (mymonth < 10) {
        mymonth = "0" + mymonth;
      }
      if (myweekday < 10) {
        myweekday = "0" + myweekday;
      }
      return myyear + "-" + mymonth + "-" + myweekday;
    },
    getTimeFn() {
      let that = this;
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
      that.timeValue[0] = that.formatDate(start);
      that.timeValue[1] = that.formatDate(end);
      this.capitalValue = [that.formatDate(start), that.formatDate(end)];
    },
    getList() {
      switch (this.$store.state.user.type) {
        case "admin"://管理员
          this.titlelist = [
            {
              title: "司机总量",
              unit: "",
              num: 0,
              tooltip: "司机总量",
              img: sj,
              url: "InquireListPage",
              name: "driverNum",
              color: "#FF8D03",
            },
            {
              title: "车辆总量",
              unit: "",
              num: 0,
              tooltip: "车辆总量",
              img: cl,
              url: "CarInquireList",
              name: "carNum",
              color: "#2C6AFF",
            },
            {
              title: "运营公司总量",
              unit: "",
              num: 0,
              tooltip: "运营公司总量",
              img: gs,
              url: "tripCompanyList",
              name: "companyNum",
              status: 1,
              color: "#4ECF86",
            },
            {
              title: "今日流调数量",
              unit: "",
              num: 0,
              tooltip: "今日流调数量",
              img: ld,
              url: "TripEventLogListPage1",
              name: "todayEventNum",
              status: 1,
              color: "#FF8D03",
            }
          ]
          break;
        case "customer"://客服
          this.titlelist = [
            {
              title: "司机总量",
              unit: "",
              num: 0,
              tooltip: "司机总量",
              img: sj,
              url: "InquireListPage",
              name: "driverNum",
              color: "#FF8D03",
            },
            {
              title: "车辆总量",
              unit: "",
              num: 0,
              tooltip: "车辆总量",
              img: cl,
              url: "CarInquireList",
              name: "carNum",
              color: "#2C6AFF",
            },
            {
              title: "运营公司总量",
              unit: "",
              num: 0,
              tooltip: "运营公司总量",
              img: gs,
              url: "tripCompanyList",
              name: "companyNum",
              status: 1,
              color: "#4ECF86",
            },
            {
              title: "今日流调数量",
              unit: "",
              num: 0,
              tooltip: "今日流调数量",
              img: ld,
              url: "",
              name: "todayEventNum",
              status: 1,
              color: "#FF8D03",
            }
          ]
          break;
        case "traffic_bureau"://派出所
          this.titlelist = [
            {
              title: "司机总量",
              unit: "",
              num: 0,
              tooltip: "司机总量",
              img: sj,
              url: "",
              name: "driverNum",
              color: "#FF8D03",
            },
            {
              title: "车辆总量",
              unit: "",
              num: 0,
              tooltip: "车辆总量",
              img: cl,
              url: "",
              name: "carNum",
              color: "#2C6AFF",
            },
            {
              title: "运营公司总量",
              unit: "",
              num: 0,
              tooltip: "运营公司总量",
              img: gs,
              url: "",
              name: "companyNum",
              status: 1,
              color: "#4ECF86",
            },
            {
              title: "今日流调数量",
              unit: "",
              num: 0,
              tooltip: "今日流调数量",
              img: ld,
              url: "",
              name: "todayEventNum",
              status: 1,
              color: "#FF8D03",
            }
          ]
          break;
        case 'motorcade':
          this.titlelist = [
            {
              title: "当前车队司机总量",
              unit: "",
              num: 0,
              tooltip: "当前车队司机总量",
              img: sj,
              url: "TripDriverList",
              name: "driverNum",
              color: "#FF8D03",
            },
            {
              title: "当前车队车辆总量",
              unit: "",
              num: 0,
              tooltip: "当前车队车辆总量",
              img: cl,
              url: "tripCarList",
              name: "carNum",
              color: "#2C6AFF",
            },
            {
              title: "当前车队今日流调数量",
              unit: "",
              num: 0,
              tooltip: "当前车队今日流调数量",
              img: ld,
              url: "",
              name: "todayEventNum",
              status: 1,
              color: "#FF8D03",
            },
          ];
          break;
        default://运营公司
          this.titlelist = [
            {
              title: "当前公司司机总量",
              unit: "",
              num: 0,
              tooltip: "当前公司司机总量",
              img: sj,
              url: "TripDriverList",
              name: "driverNum",
              color: "#FF8D03",
            },
            {
              title: "当前公司车辆总量",
              unit: "",
              num: 0,
              tooltip: "当前公司车辆总量",
              img: cl,
              url: "tripCarList",
              name: "carNum",
              color: "#2C6AFF",
            },
            {
              title: "当前公司今日流调数量",
              unit: "",
              num: 0,
              tooltip: "当前公司今日流调数量",
              img: ld,
              url: "",
              name: "todayEventNum",
              status: 1,
              color: "#FF8D03",
            },
          ];
      }
    },
  },
  async created() {
    this.getTimeFn();
    await this.getList();
    await this.getFindWaitingWorkList();
    // await this.findUserAccountAmountList();
  },
  mounted() {
    this.getFindOrderEveryDayAmount();
  },
};
</script>
<style lang="scss" scoped>
.border {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
}

.titleText {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
}

.toplist {
  // height: 100px;
  padding: 20px;
  padding-bottom: 0;
  display: flex;
  justify-content: baseline;
  // /deep/.el-col-5 {
  //   max-width: 18%;
  //   min-width: 11%;
  // }
}

.toptag {
  display: flex;
  align-items: center;
  height: 100px;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0px 3px 6px 0px rgba(179, 194, 171, 0.3);
  border-radius: 5px;
  min-width: 240px;
  width: auto;
  margin: 15px;
  cursor: pointer;

  .topimg {
    margin-left: 18px;
    height: auto;
    width: 48px;
  }

  .toptitle {
    font-weight: 400;
    font-size: 12px;
    display: block;
    white-space: nowrap;
    width: fit-content;
    margin: 0 25px 0 25px;
    color: #7e87a7;
  }

  .toptnum {
    margin: 0;
    margin-top: 15px;
    margin-left: 25px;
    font-size: 30px;
    font-family: Helvetica;
    font-weight: 700;
    color: #242f57;
  }
}

.topLists {
  height: 100px;
  padding: 20px;
  padding-bottom: 30px;
  padding-left: 20px;

  /deep/.el-col-5 {
    max-width: 18%;
    min-width: 11%;
  }

  .toptag {
    height: 82px;

    .topimg {
      margin-left: 16px;
      width: 30px;
    }
  }

  .toptagBox:nth-child(1) .toptag {
    background: rgba(19, 194, 194, 0.1);
    box-shadow: 0px 2px 4px 0px rgba(179, 194, 171, 0.2);
    border-radius: 8px;
  }

  .toptagBox:nth-child(2) .toptag {
    background: rgba(255, 122, 69, 0.1);
    box-shadow: 0px 2px 4px 0px rgba(179, 194, 171, 0.2);
    border-radius: 8px;
  }

  .toptagBox:nth-child(3) .toptag {
    background: rgba(250, 219, 20, 0.1);
    box-shadow: 0px 2px 4px 0px rgba(179, 194, 171, 0.2);
    border-radius: 8px;
  }

  .toptagBox:nth-child(4) .toptag {
    background: rgba(247, 89, 171, 0.1);
    box-shadow: 0px 2px 4px 0px rgba(179, 194, 171, 0.2);
    border-radius: 8px;
  }
}

.aaac {
  font-size: 12px;
  display: inline-block;
  padding: 0;
  margin-left: 8px;
  margin-right: 17px;
  line-height: 14px;
  height: 14px;
  width: 14px;
  font-style: normal;
  color: #c0c4cc;
  border: 1px solid #c0c4cc;
  border-radius: 50%;
  text-align: center;
}

.titleContent {
  width: 100%;
  font-size: 18px;
  font-weight: 400;
  color: #000000;
  height: 59px;
  line-height: 59px;
  border-bottom: 1px solid #e8e8e8;
  box-sizing: border-box;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
}

.ulBox {
  height: 70px;
  display: flex;
  line-height: 70px;
  padding-left: 20px;

  .img {
    width: 25px;

    img {
      width: 100%;
      height: auto;
    }
  }

  .gonggaoRigBox {
    flex: 1;
    display: flex;
    font-size: 14px;
    border-bottom: #e8e8e8 solid 1px;

    .title {
      color: #666666;
      cursor: pointer;
      flex: 1;
      margin: 0 12px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
    }

    .time {
      color: #999999;
      width: 160px;
      margin: 0 20px;
    }

    .btn {
      width: 82px;
      margin-left: 10px;
      margin-right: 30px;

      span {
        cursor: pointer;
        display: inline-block;
        width: 100%;
        height: 40px;
        text-align: center;
        line-height: 40px;
        border-radius: 8px;
        border: 1px solid #4bb019;
        color: #4bb019;
      }
    }
  }
}

.ulBox:last-child>.gonggaoRigBox {
  border: 0;
}

.OtherList {
  // box-sizing: border-box;
  display: flex;
  height: 40px;
  line-height: 40px;
  padding: 20px;
  font-size: 18px;
  color: #000;

  div:hover {
    span {
      padding: 7px;
      width: 99px;
      background: rgba(75, 176, 25, 0.1);
      border-radius: 8px;
      height: 40px;
      color: #4bb019;
    }
  }

  div {
    margin-right: 45px;
    cursor: pointer;

    span {
      padding: 7px;
    }
  }

  div:last-child {
    margin: 0;
  }

  div:hover {
    color: royalblue;
  }
}

.navTitle {
  height: 54px;
  line-height: 54px;
  padding-bottom: 16px;
  border-bottom: 1px solid #e8e8e8;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  padding: 0;
  padding-left: 20px;
}

.tabTitle {
  padding-top: 30px;
  padding-left: 20px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;

  .text {
    color: #848cab;
    font-size: 18px;
  }
}

.dingdanList {
  height: 440px;
  overflow-y: scroll;
}

.gougaoList {
  height: 700px;
  overflow-y: scroll;
}

.page-example3 {
  width: 600px;
  height: 59px;
  overflow: hidden;
  position: relative;

  .img {
    position: absolute;
    top: 5px;
  }

  .spanright {
    position: absolute;
    right: 0;
    top: 1px;
    color: #4bb019;
    cursor: pointer;
    font-size: 14px;
  }

  .ul-scoll {
    li {
      padding: 0;
      margin: 0;
      list-style: none;
      height: 30px;
      line-height: 40px;
      font-size: 14px;
      color: #666666;

      .spanleft {
        float: left;
        width: 300px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        cursor: pointer;
      }
    }
  }
}

.titleBox {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
}
</style>

